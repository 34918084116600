<template>
    <div class="cEmail">
        <div class="emailipt">
            <ul>
                <li v-show="oEmail.email != null">
                    <div class="item-name">{{this.$t('舊信箱地址：')}}</div>
                    <div class="email-container clearfix">
                        <div class="num-container">
                            <div class="top">
                            <div class="email-box">
                                <div class="email-box-ipt">
                                    <a-input
                                    v-model="oEmail.email"
                                    disabled
                                    placeholder="01XXXXXXXXX"
                                    type="text" 
                                    />
                                    <button v-show="countshow" @click="getCode(0)">
                                    <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                                    <span v-else>{{ this.$t("驗證碼") }}</span>
                                    </button>
                                    <button style="background: #d9d9d9; color: #333" v-show="!countshow">
                                    <span class="count">{{ count }} s</span>
                                    </button>
                                </div>
                                <div class="redcolor">{{emailerr}}</div>
                            </div>
                            </div>
                        </div>
                        <div class="code-container">
                            <div>
                            <a-input 
                                v-model="oCode"
                                :placeholder="this.$t('請輸入驗證碼')"
                                type="text"
                            />
                            <div class="redcolor">{{codeerr}}</div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="item-name">{{this.$t('新信箱地址：')}}</div>
                    <div class="email-container clearfix">
                        <div class="num-container">
                            <div class="top">
                            <div class="email-box">
                                <div class="email-box-ipt">
                                    <a-input
                                    v-model="nEmail"
                                    placeholder="01XXXXXXXXX"
                                    type="text" 
                                    />
                                    <button v-show="countshow1" @click="getCode(1)">
                                    <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                                    <span v-else>{{ this.$t("驗證碼") }}</span>
                                    </button>
                                    <button style="background: #d9d9d9; color: #333" v-show="!countshow1">
                                    <span class="count">{{ count1 }} s</span>
                                    </button>
                                </div>
                                <div class="redcolor">{{emailerragain}}</div>
                            </div>
                            </div>
                        </div>
                        <div class="code-container">
                            <div>
                            <a-input 
                                v-model="nCode"
                                :placeholder="this.$t('請輸入驗證碼')"
                                type="text"
                            />
                            <div class="redcolor">{{codeerragain}}</div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="btn-container">
            <p @click="canel()"><span>{{this.$t('取消')}}</span></p>
            <p @click="getSure()">
                <loading v-show="isLoad"></loading>
                <span style="display: inline-block; margin-left: 10px">{{
                this.$t("確認")
                }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import loading from '../../../../components/Loading'
import request from "../../../../api/request";
import validate from "../../../../utils/validate";
export default {
    components: {
        loading
    },
    data(){
        return{
            fullWidth: 0,
            isLoad:false,
            oEmail:JSON.parse(window.localStorage.getItem("user_info")),
            countshow: true,
            count: '',
            timer: null,
            oCode:'',
            nEmail:'',
            nCode:'',
            countshow1: true,
            count1: '',
            timer1: null,
            codeerr:'',
            codeerragain:'',
            emailerr:'',
            emailerragain:''
        }
    },
    methods:{
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
        getSure(){
            let _this = this
            if(_this.oEmail.email == null){
                let res = validate.code(_this.nCode)
                if(res){
                    _this.codeerragain = _this.$t(res)
                    return
                }else{
                    _this.codeerr = ''
                    _this.codeerragain = ''
                    _this.emailerr = ''
                    _this.emailerragain = ''
                    let data = {
                        code:_this.nCode,
                        email:_this.nEmail,
                        verify_source:2,
                        verify_type:3
                    }
                    _this.isLoad = true
                    request.post('/verify', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("绑定成功"));
                            _this.isLoad = false
                            setTimeout(function (){
                                _this.$router.go(0)
                            }, 2000)
                        }else{
                            _this.$message.error(res.detail);
                            _this.isLoad = false
                        }
                    })
                }
            }
            if(_this.oEmail.email != null){
                let res = validate.code(_this.oCode)
                let resagain = validate.code(_this.nCode)
                if(res){
                    _this.codeerr = _this.$t(res)
                    return
                }else if(resagain){
                    _this.codeerragain = _this.$t(resagain)
                    return
                }else{
                    _this.codeerr = ''
                    _this.codeerragain = ''
                    _this.emailerr = ''
                    _this.emailerragain = ''
                    let data = {"new_email":{"code":_this.nCode,"email":_this.nEmail,"verify_source":2,"verify_type":3},"old_email":{"code":_this.oCode,"email":_this.oEmail.email,"verify_source":2,"verify_type":3}}
                    _this.isLoad = true
                    request.post('/verify/email', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("修改成功"));
                            _this.isLoad = false
                            setTimeout(function (){
                                _this.$router.go(0)
                            }, 2000)
                        }else{
                            _this.isLoad = false
                            _this.$message.error(res.detail);
                        }
                    })
                }
                
            }
        },
        getCode(num){
            const TIME_COUNT = 60;
            let _this = this
            if(num == 0){
                let res = validate.email(_this.oEmail.email)
                if(res){
                    _this.emailerr = _this.$t(res)
                    return
                }else{
                    _this.emailerr = ''
                    if (!_this.timer) {
                        _this.count = TIME_COUNT;
                        _this.countshow = false;
                        _this.timer = setInterval(() => {
                        if (_this.count > 0 && _this.count <= TIME_COUNT) {
                        _this.count--;
                        } else {
                        _this.countshow = true;
                        clearInterval(_this.timer);
                        _this.timer = null;
                        }
                        }, 1000)
                    }
                    let data = {
                        email:_this.oEmail.email,
                        verify_source:2,
                        verify_type:3
                    }
                    request.post('/verify/send', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("發送成功"));
                        }
                    })
                }
            }
            if(num == 1){
                let res = validate.email(_this.nEmail)
                if(res){
                    _this.emailerragain = _this.$t(res)
                    return
                }else{
                    _this.emailerragain = ''
                    if (!_this.timer1) {
                        _this.count1 = TIME_COUNT;
                        _this.countshow1 = false;
                        _this.timer = setInterval(() => {
                        if (_this.count1 > 0 && _this.count1 <= TIME_COUNT) {
                        _this.count1--;
                        } else {
                        _this.countshow1 = true;
                        clearInterval(_this.timer1);
                        _this.timer1 = null;
                        }
                        }, 1000)
                    }
                    if(_this.oEmail.email == null){
                        let data = {
                            email:_this.nEmail,
                            verify_source:2,
                            verify_type:3
                        }
                        request.post('/verify/send', data).then(function (res) {
                            if (res.status == 200) {
                                _this.$message.success(_this.$t("發送成功"));
                            }
                        })
                    }
                    if(_this.oEmail.email != null){
                        let data = {
                            email:_this.nEmail,
                            verify_source:2,
                            verify_type:3
                        }
                        request.post('/verify/send', data).then(function (res) {
                            if (res.status == 200) {
                                _this.$message.success(_this.$t("發送成功"));
                            }
                        })
                    }
                }
            }
        },
        canel(){
            this.$router.go(0)
        }
    },
    mounted() {
        this.handleResize()
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss">
.cEmail{
    .emailipt{
        ul{
            li{
                margin: 20px 0;
                display: flex;
                .item-name{
                   line-height: 50px; 
                   width: 20%;
                   white-space: nowrap;
                }
            }
        }
        input{
            font-size: 18px;
        }
        .email-container{
            .num-container {
                float: left;
                margin-right: 20px;
                width: calc(80% - 20px);
                @media only screen and (max-width: 900px) {
                    width: 100%;
                }
                .top {
                    display: flex;
                    select {
                        width: 30%;
                        margin-right: 20px;
                        font-weight: 300;
                        color: #999;
                        border: 1px solid #d9d9d9;
                        background: transparent;
                        height: 50px;
                        padding: 0 10px;
                    option {
                            border: none;
                            background: transparent;
                        }
                    }
                    .email-box {
                        display: inline-block;
                        width: calc(100% - 20px);
                        .email-box-ipt{
                            border: 1px solid #d9d9d9;
                        }
                        input {
                            width: 60%;
                            height: 50px;
                            line-height: 50px;
                            color: #222;
                            border: none;
                            outline: none;
                            background: transparent;
                        }
                        button {
                                width: 150px;
                                height: 30px;
                                line-height: 30px;
                                font-size: 18px;
                                background-color: #c6a86c;
                                border-radius: 40px;
                            }
                        }
                    }
                }
            .code-container {
                float: left;
                width: 20%;
                input {
                    height: 50px;
                    line-height: 50px;
                    color: #222;
                    border: 1px solid #d9d9d9;
                }
            }
        }
    }
}
</style>