<template>
    <div class="unbund">
        <div class="unbund-tab-bar">
            <span
              @click="tab(index)"
              v-for="(item,index) in items"
              :key="index"
              :class="{unbundactive : index===curId}"
            >
            {{item.item}}
            </span>
        </div>
        <div class="unbund-tab-cont">
            <div class="unbund-title redcolor">
                {{this.$t('您正在解绑手机，需要验证旧手机，若旧手机无法接收验证码，请使用邮箱进行验证。')}}
            </div>
            <!-- phone -->
            <div v-if="curId === 0">
                <div>
                    <div class="phone-ipt">
                        <div class="item-name">{{this.$t('手機：')}}</div>
                        <div class="phone-container clearfix">
                            <div class="num-container">
                                <div class="top">
                                <select v-model="aaa" name="num">
                                    <option value="82">KR+82</option>
                                    <!-- <option value="886">TW+886</option>
                                    <option value="852">HK+852</option>
                                    <option value="853">MC+853</option>
                                    <option value="66">66</option> -->
                                </select>
                                <div class="phone-box">
                                    <div class="phone-box-ipt">
                                        <a-input 
                                        v-model="phone"
                                        placeholder="01XXXXXXXXX"
                                        type="text" 
                                        />
                                        <button v-show="countshow" @click="getCode(0)">
                                        <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                                        <span v-else>{{ this.$t("驗證碼") }}</span>
                                        </button>
                                        <button style="background: #d9d9d9; color: #333" v-show="!countshow">
                                        <span class="count">{{ count }} s</span>
                                        </button>
                                    </div>
                                    <div class="redcolor">{{phoneErr}}</div>
                                </div>
                                </div>
                            </div>
                            <div class="code-container">
                                <div>
                                <a-input 
                                    v-model="code"
                                    :placeholder="this.$t('請輸入驗證碼')"
                                    type="text"
                                />
                                <div class="redcolor">{{phonecodeErr}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="$store.state.user_info.username == $store.state.user_info.phone" class="phone-ipt">
                        <div class="item-name">{{this.$t('帳號：')}}</div>
                        <div class="nameCon">
                            <a-input v-model="phoneName" :placeholder="this.$t('請輸入帳號')" />
                            <div class="redcolor">{{phoneNameErr}}</div>
                        </div>
                    </div>
                </div>
                
            </div>
            <!-- email -->
            <div v-if="curId === 1">
                <div>
                    <div class="email-ipt">
                        <div class="item-name">{{this.$t('信箱：')}}</div>
                        <div class="email-container clearfix">
                            <div class="num-container">
                                <div class="top">
                                <div class="email-box">
                                    <div class="email-box-ipt">
                                        <a-input
                                        v-model="email"
                                        placeholder="01XXXXXXXXX"
                                        type="text" 
                                        />
                                        <button v-show="countshow1" @click="getCode(1)">
                                        <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                                        <span v-else>{{ this.$t("驗證碼") }}</span>
                                        </button>
                                        <button style="background: #d9d9d9; color: #333" v-show="!countshow1">
                                        <span class="count">{{ count1 }} s</span>
                                        </button>
                                    </div>
                                    <div class="redcolor">{{emailErr}}</div>
                                </div>
                                </div>
                            </div>
                            <div class="code-container">
                                <div>
                                <a-input 
                                    v-model="emailcode"
                                    :placeholder="this.$t('請輸入驗證碼')"
                                    type="text"
                                />
                                <div class="redcolor">{{emailcodeErr}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="$store.state.user_info.username == $store.state.user_info.phone" class="email-ipt">
                        <div class="item-name">{{this.$t('帳號：')}}</div>
                        <div class="nameCon">
                            <a-input v-model="emailName" :placeholder="this.$t('請輸入帳號')" />
                            <div class="redcolor">{{emailNameErr}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-container">
                <p @click="canel()"><span>{{this.$t('取消')}}</span></p>
                <p @click="getSure()">
                    <loading v-show="isLoad"></loading>
                    <span style="display: inline-block; margin-left: 10px">{{
                    this.$t("確認")
                    }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import loading from '../../../../components/Loading'
import request from "../../../../api/request"
import validate from "../../../../utils/validate"
export default {
    components: {
        loading
    },
    data(){
        return{
            fullWidth:0,
            curId: 0,
            isLoad:false,
            items: [
                {item: this.$t('手機')},
                {item: this.$t('信箱')},
            ],
            aaa: "82",
            phone:'',
            code:'',
            countshow: true,
            count: '',
            timer: null,
            email:'',
            emailcode:'',
            countshow1: true,
            count1: '',
            timer1: null,
            phoneErr:'',
            phonecodeErr:'',
            emailErr:'',
            emailcodeErr:'',
            phoneName:'',
            emailName:'',
            phoneNameErr:'',
            emailNameErr:''
        }
    },
    methods:{
        tab(index) {
            this.curId = index;
        },
        getCode(num){
            if(num == 0){
                let _this = this
                let res = validate.phone(_this.phone)
                if(res){
                    _this.phoneErr = _this.$t(res)
                    return
                }else{
                    _this.phoneErr = ''
                    const TIME_COUNT = 60;
                    if (!_this.timer) {
                        _this.count = TIME_COUNT;
                        _this.countshow = false;
                        _this.timer = setInterval(() => {
                        if (_this.count > 0 && _this.count <= TIME_COUNT) {
                            _this.count--;
                            } else {
                            _this.countshow = true;
                            clearInterval(_this.timer);
                            _this.timer = null;
                            }
                        }, 1000)
                    }
                    let data = {
                        phone:_this.phone,
                        areacode:_this.aaa,
                        verify_source:1,
                        verify_type:2
                    }
                    request.post('/verify/send', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("發送成功"));
                        }
                    })
                }
            }
            if(num == 1){
                let _this = this
                let res = validate.email(_this.email)
                if(res){
                    _this.emailErr = _this.$t(res)
                    return
                }else{
                    _this.emailErr = ''
                    const TIME_COUNT = 60;
                    if (!_this.timer1) {
                        _this.count1 = TIME_COUNT;
                        _this.countshow1 = false;
                        _this.timer1 = setInterval(() => {
                        if (_this.count1 > 0 && _this.count1 <= TIME_COUNT) {
                            _this.count1--;
                            } else {
                            _this.countshow1 = true;
                            clearInterval(_this.timer1);
                            _this.timer1 = null;
                            }
                        }, 1000)
                    }
                    let data = {
                        email:_this.email,
                        verify_source:2,
                        verify_type:2
                    }
                    request.post('/verify/send', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("發送成功"));
                        }
                    })
                }
            }
        },
        getSure(){
            let _this = this
            if(_this.curId == 0){
                if(_this.$store.state.user_info.username == _this.$store.state.user_info.phone && _this.phoneName == ''){
                    _this.phoneNameErr = _this.$t("請輸入帳號");
                    return
                }
                _this.phoneNameErr = '';
                let data;
                if(_this.$store.state.user_info.username == _this.$store.state.user_info.phone){
                    data = {
                        old_phone: {
                            phone: _this.phone,
                            areacode: _this.aaa,
                            verify_source: 1,
                            verify_type: 2,
                            code: _this.code
                        },
                        username:_this.phoneName
                    }
                }else{
                    data = {
                        old_phone: {
                            phone: _this.phone,
                            areacode: _this.aaa,
                            verify_source: 1,
                            verify_type: 2,
                            code: _this.code
                        },
                    }
                }
                _this.isLoad = true
                request.post('/verify/phone', data).then(function (res) {
                    if (res.status == 200) {
                        _this.$message.success(_this.$t("解绑成功"));
                        _this.isLoad = false
                        setTimeout(function (){
                            _this.$router.go(0)
                        }, 2000)
                    }
                })
            }
            if(_this.curId == 1){
                if(_this.$store.state.user_info.username == _this.$store.state.user_info.phone && _this.emailName == ''){
                    _this.emailNameErr = _this.$t("請輸入帳號");
                    return
                }
                _this.emailNameErr = ''
                let data;
                if(_this.$store.state.user_info.username == _this.$store.state.user_info.phone){
                    data = {
                        old_email: {
                            email: _this.email,
                            verify_source: 2,
                            verify_type: 2,
                            code: _this.emailcode
                        },
                        username:_this.emailName
                    }
                }else{
                    data = {
                        old_email: {
                            email: _this.email,
                            verify_source: 2,
                            verify_type: 2,
                            code: _this.emailcode
                        },
                    }
                }
                _this.isLoad = false
                request.post('/verify/phone', data).then(function (res) {
                    if (res.status == 200) {
                        _this.$message.success(_this.$t("解绑成功"));
                        _this.isLoad = false
                        setTimeout(function (){
                            _this.$router.go(0)
                        }, 2000)
                    }
                })
            }
        },
        canel(){
            this.$router.go(0)
        },
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
    },
    mounted() {
        this.handleResize()
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss">
.unbund{
    margin-top: 20px;
    font-size: 20px;
    .unbund-tab-bar{
        span{
            color: #909499;
            cursor: pointer;
            display: inline-block;
            margin-right: 20px;
            text-align: center;
            font-weight: bold;
            padding: 2px 0;
            width: 100px;
            font-size: 20px;
        }
    }
    .unbund-tab-cont{
        margin-top: 50px;
        .unbund-title{
            margin: 50px 0;
            text-align: center;
        }
        .phone-ipt{
            display: flex;
            margin-bottom: 20px;
            .item-name{
                line-height: 50px; 
                width: 20%;
            }
            .nameCon{
                width: 80%;
                input{
                    height: 50px;
                    line-height: 50px;
                    color: #222;
                    outline: none;
                }
            }
            .phone-container {
                width: 80%;
                .num-container {
                    float: left;
                    margin-right: 20px;
                    width: calc(80% - 20px);
                    @media only screen and (max-width: 900px) {
                        width: 100%;
                    }
                    .top {
                        display: flex;
                            select {
                                width: 25%;
                                margin-right: 20px;
                                font-weight: 300;
                                color: #999;
                                border: 1px solid #d9d9d9;
                                background: transparent;
                                height: 50px;
                                padding: 0 10px;
                                option {
                                    border: none;
                                    background: transparent;
                                }
                            }
                        .phone-box {
                            display: inline-block;
                            width: calc(70% - 20px);
                            .phone-box-ipt{
                                border: 1px solid #d9d9d9;
                            }
                        input {
                            width: 55%;
                            height: 50px;
                            line-height: 50px;
                            color: #222;
                            border: none;
                            outline: none;
                            background: transparent;
                        }
                        button {
                                width: 45%;
                                height: 30px;
                                line-height: 30px;
                                font-size: 18px;
                                background-color: #c6a86c;
                                border-radius: 40px;
                            }
                        }
                    }
                }
                .code-container {
                    float: left;
                    width: 20%;
                    input {
                        height: 50px;
                        line-height: 50px;
                        color: #222;
                        border: 1px solid #d9d9d9;
                    }
                }
            }
        }
        .email-ipt{
            display: flex;
            margin-bottom: 20px;
            .item-name{
                line-height: 50px; 
                width: 15%;
            }
            .nameCon{
                width: 80%;
                input{
                    height: 50px;
                    line-height: 50px;
                    color: #222;
                    outline: none;
                }
            }
            .email-container{
                width: 85%;
                .num-container {
                    float: left;
                    margin-right: 20px;
                    width: calc(80% - 20px);
                    @media only screen and (max-width: 900px) {
                        width: 100%;
                    }
                    .top {
                        display: flex;
                        select {
                            width: 30%;
                            margin-right: 20px;
                            font-weight: 300;
                            color: #999;
                            border: 1px solid #d9d9d9;
                            background: transparent;
                            height: 50px;
                            padding: 0 10px;
                        option {
                                border: none;
                                background: transparent;
                            }
                        }
                        .email-box {
                            display: inline-block;
                            width: calc(100% - 20px);
                            .email-box-ipt{
                                border: 1px solid #d9d9d9;
                            }
                            input {
                                width: 60%;
                                height: 50px;
                                line-height: 50px;
                                color: #222;
                                border: none;
                                outline: none;
                                background: transparent;
                            }
                            button {
                                    width: 40%;
                                    height: 30px;
                                    line-height: 30px;
                                    font-size: 18px;
                                    background-color: #c6a86c;
                                    border-radius: 40px;
                                }
                            }
                        }
                    }
                .code-container {
                    float: left;
                    width: 20%;
                    input {
                        height: 50px;
                        line-height: 50px;
                        color: #222;
                        border: 1px solid #d9d9d9;
                    }
                }
            }
        }
    }
    .unbundactive{
        color: #fff !important;
        background: #3E46FE;
    }
    .btn-container{
        width: 80%;
        margin: 50px auto;
        display: flex;
        p{
            cursor: pointer;
            width: 45%;
            text-align: center;
            color: #fff;
            height: 50px;
            line-height: 50px;
            font-size: 22px;
            &:nth-child(1){
                background: #C5C9CE;
                margin-right: 30px;
            }
            &:nth-child(2){
                background: #2C82FF;
            }
        }
    }
}
</style>
