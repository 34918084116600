<template>
    <div class="cName">
        <div class="nameipt">
            <div class="item">{{this.$t('暱稱：')}}</div>
            <div>
                <p><a-input v-model="username" :placeholder="this.$t('輸入新暱稱')" /></p>
                <p class="redcolor">{{err}}</p>
            </div>
        </div>
        <div class="btn-container">
            <p @click="canel()"><span>{{this.$t('取消')}}</span></p>
            <p @click="getSure()">
                <loading v-show="isLoad"></loading>
                <span style="display: inline-block; margin-left: 10px">{{
                this.$t("確認")
                }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import loading from '../../../../components/Loading'
import request from "../../../../api/request";
export default {
    components: {
        loading
    },
    data(){
        return{
            isLoad:false,
            username:'',
            err:this.$t('暱稱僅有一次修改機會')
        }
    },
    methods:{
        getSure(){
            let _this = this
            if(_this.$store.state.user_info.nickname == _this.username){
                _this.err = _this.$t('昵称重复');
                return
            }else if(_this.username == ''){
                _this.err = _this.$t('请输入昵称');
                return
            }else{
                _this.err = ''
                let data = {
                    nickname:_this.username
                }
                _this.isLoad = true
                request.put('/users/me', data, true).then(function (res) {
                    if (res.status == 200) {
                        _this.$message.success(_this.$t("修改成功"));
                        _this.$store.commit("setUserInfo", res.data);
                        _this.isLoad = false
                        setTimeout(function (){
                            _this.$router.go(0)
                        }, 2000)
                    }
                    else {
                        _this.$message.error(res.detail);
                        _this.username = ''
                        _this.isLoad = false
                    }
                })
            }
        },
        canel(){
            this.$router.go(0)
        }
    }
}
</script>

<style lang="scss">
.cName{
    .nameipt{
        display: flex;
        font-size: 16px;
        .item{
            height: 50px;
            line-height: 50px;
        }
        input{
            height: 50px;
            line-height: 50px;
            font-size: 18px;
        }
        div{
            &:nth-child(1){
                width: 15%;
            }
            &:nth-child(2){
                width: 85%;
            }
        }
    }
}
</style>