<template>
    <div class="changepwd">
        <div class="pwd-tab-bar">
            <span
              @click="tab(index)"
              v-for="(item,index) in items"
              :key="index"
              :class="{passwordactive : index===curId}"
            >
            {{item.item}}
            </span>
        </div>
        <div class="pwd-tab-cont">
            <pwdPhone v-if="curId === 0"></pwdPhone>
            <pwdEmail v-else-if="curId === 1"></pwdEmail>
        </div>
    </div>
</template>

<script>
import pwdEmail from './PwdChange/pwdEmail'
import pwdPhone from './PwdChange/pwdPhone'
export default {
    components: {
        pwdEmail,
        pwdPhone
    },
    data(){
        return{
            curId: 0,
            items: [
                {item: this.$t('手機')},
                {item: this.$t('信箱')},
            ]
        }
    },
    methods:{
        tab(index) {
            this.curId = index;
        }
    }
}
</script>

<style lang="scss">
.changepwd{
    margin-top: 20px;
    font-size: 20px;
    .pwd-tab-bar{
        span{
            color: #909499;
            cursor: pointer;
            display: inline-block;
            margin-right: 20px;
            text-align: center;
            font-weight: bold;
            padding: 2px 0;
            width: 100px;
            font-size: 20px;
        }
    }
    .pwd-tab-cont{
        margin-top: 50px;
    }
    .passwordactive{
        color: #fff !important;
        background: #3E46FE;
    }
    .btn-container{
        width: 80%;
        margin: 50px auto;
        display: flex;
        p{
            cursor: pointer;
            width: 45%;
            text-align: center;
            color: #fff;
            height: 50px;
            line-height: 50px;
            font-size: 22px;
            &:nth-child(1){
                background: #C5C9CE;
                margin-right: 30px;
            }
            &:nth-child(2){
                background: #2C82FF;
            }
        }
    }
}
</style>