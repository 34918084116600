<template>
    <div class="userdata">
        <div class="tab-bar">
            <span
              @click="tab(index)"
              v-for="(item,index) in items"
              :key="index"
              :class="{userdataactive : index===curId}"
            >
            {{item.item}}
            </span>
        </div>
        <div class="tab-cont">
            <Datas v-if="curId === 0"></Datas>
            <Password v-else-if="curId === 1"></Password>
            <RealName v-else-if="curId === 2"></RealName>
            <CancelAccount v-else></CancelAccount>
        </div>
    </div>
</template>

<script>
import RealName from './RealName.vue'
import Datas from './Datas.vue'
import Password from './Password.vue'
import CancelAccount from './CancelAccount'
export default {
    components: {
        RealName,
        Datas,
        Password,
        CancelAccount
    },
    data(){
        return{
            curId: 0,
            items: [
                {item: this.$t('我的資料')},
                {item: this.$t('密碼更改')},
                {item: this.$t('實名認證')},
                {item: this.$t('注销账号')}
            ]
        }
    },
    beforeCreate(){
        var query=this.$route.query;
        let token = query.token;
        if (token) {
            this.$store.commit("setToken", token);
            this.$router.replace({
                path: this.$route.path,
                query: {tab: query.ctab}})
        }
    },
    mounted(){
        if (this.$route.query.tab) {
            let currentTab = parseInt(this.$route.query.tab)
            this.curId = currentTab
        }  
    },
    methods:{
        tab(index) {
            this.curId = index;
        }
    },
    watch:{
        $route(){
            let currentTab = parseInt(this.$route.query.tab)
            this.curId = currentTab
        },
    }
}
</script>

<style lang="scss">
.userdata{
    margin-top: 20px;
    .tab-bar{
        span{
            font-size: 22px;
            color: #909499;
            cursor: pointer;
            display: inline-block;
            margin-right: 20px;
            padding-bottom: 10px;
            font-weight: bold;
        }
    }
    .userdataactive{
        color: #3E46FE !important;
        border-bottom: 1px solid #3E46FE;
    }
}
</style>