<template>
    <div class="realname">
        <div v-if="$store.state.user_info && $store.state.user_info.birthdate != null" class="is-real">
            <ul>
                <li>
                    <div class="item-name">{{this.$t('姓名')}}：</div>
                    <div>{{$store.state.user_info.realname}}</div>
                </li>
                <li>
                    <div class="item-name">EX：</div>
                    <div>{{$store.state.user_info.birthdate}}</div>
                </li>
            </ul>
        </div>
        <div v-else class="real">
            <div class="real-top">
                <div>
                    <img src="../../../assets/img/real.png" alt="">
                </div>
                <div>
                    <p>{{this.$t('实名验证是一种注册过程，用于在您首次注册时验证您的身份。')}}</p>
                    <p style="white-space: nowrap;">{{this.$t('输入的信息将提供给NICE信用评级信息有限公司，以确认其真实姓名。')}}</p>
                    <p class="redcolor">{{this.$t('PangSky Co.，Ltd.不收集居民登记号。')}}</p>
                </div>
            </div>
            <div class="real-text">
                <p><span class="redcolor">{{this.$t('注意：')}}</span>{{this.$t('如果您窃取他人的居民登记号码和信息，根据居民登记号码，您可能会受到最高3年的监禁或最高1000万韩元的罚款，并且会员登记可能被取消，恕不另行通知。')}}：</p>
            </div>
            <div class="real-cont">
                <div class="real-ipt">
                    <p>
                        <a-checkbox :checked="check1" @change="onChange1">
                            {{this.$t('我同意接受 服务条款及条件')}}
                        </a-checkbox>
                    </p>
                    <p>
                        <a-checkbox :checked="check2" @change="onChange2">
                            {{this.$t('我同意收集和使用个人信息')}}
                        </a-checkbox>
                    </p>
                </div>
                <div class="real-btn">
                    <a-button @click="getReal()" type="primary"><loading v-show="isLoad"></loading><span style="display: inline-block; margin-left: 10px">{{
                this.$t("確認")
                }}</span></a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import loading from '../../../components/Loading'
import request from "../../../api/request"

export default {
    name: 'username',
    components: {
        loading
    },
    data() {
        return {
            fullWidth: 0,
            isLoad: false,
            check1:true,
            check2:true,
        }
    },
    methods: {
        numCanel(){
            this.$router.go(-1);
        },
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
        onChange1(e) {
            this.check1 = e.target.checked
        },
        onChange2(e) {
            this.check2 = e.target.checked
        },
        getReal(){
            let _this = this
            if(_this.check1 == false){
                console.log('请选择')
                return
            }else if(_this.check2 == false){
                console.log('请选择')
                return
            }else{
                _this.isLoad = true
                let data = {
                    auth_source:'nice'
                }
                request.post('/thirdauths', data).then(function (res) {
                    if (res.status == 200) {
                        _this.isLoad = false
                        window.open(
                        res.data.url, 'popupChk',
                        'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                    }else{
                        _this.isLoad = false
                    }
                })
            }
        },
    },
    mounted() {
        this.handleResize()
        if (this.$route.query.detail) {
            this.$message.error(this.$route.query.detail);
        }
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss">
.realname{
    margin-top: 30px;
    font-size: 20px;
    .is-real{
        ul{
            li{
                display: flex;
                font-size: 20px;
                margin: 20px 0;
                .item-name{
                    width: 10%;
                }
            }
        }
    }
    .real{
        .real-top{
            display: flex;
            border-bottom: 1px solid #E1E1E1;
            div{
                padding: 10px 10px;
                &:nth-child(2){
                    margin-top: 30px;
                }
                img{
                    border-right: 1px solid #E1E1E1;
                }
            }
        }
        .real-text{
            margin: 30px 0;
        }
        .real-cont{
            p{
                margin-bottom: 20px;
            }
            input{
                height: 50px;
                line-height: 50px;
            }
            span{
                font-size: 20px;
            }
            .ant-checkbox-inner{
                width: 30px;
                height: 30px;
            }
            .ant-checkbox-inner::after{
                width: 10px;
                height: 20px;
            }
        }
        .real-btn{
            text-align: center;
            button{
                width: 50%;
                height: 50px;
                line-height: 50px;
                margin: 50px auto;
                font-size: 20px;
            }
        }
    }
}
</style>