<template>
    <div class="data">
        <ul class="data-container" v-if="!cname && !cphone && !cemail && !isBund">
            <li>
                <div>{{this.$t('暱稱：')}}</div>
                <div>{{$store.state.user_info.nickname === ''? '-' : $store.state.user_info.nickname}}</div>
                <div v-if="$store.state.user_info.nickname_is_new == true"><a-button @click="clickChangeData" type="primary">{{this.$t('修改')}}</a-button></div>
            </li>
            <li>
                <div>{{this.$t('帳號：')}}</div>
                <div>{{$store.state.user_info.username === ''? '-' : $store.state.user_info.username}}</div>
            </li>
            <li>
                <div>{{this.$t('信箱：')}}</div>
                <div>
                    <p>{{$store.state.user_info.email === ''? '-' : $store.state.user_info.email}}</p>
                    <p>
                        <a-checkbox :checked="check1" @change="onChangecheck1">
                            {{this.$t("同意接受 Panggame 消息及廣告訊息")}}
                        </a-checkbox>
                    </p>
                </div>
                <div>
                    <a-button @click="clickChangeEmail" type="primary">
                        <span v-if="$store.state.user_info.email">{{this.$t('修改')}}</span>
                        <span v-else>{{this.$t('添加')}}</span>
                    </a-button>
                </div>
            </li>
            <li>
                <div>{{this.$t('手機號碼：')}}</div>
                <div>
                    <p>{{$store.state.user_info.phone === '' ? '-' : $store.state.user_info.phone}}</p>
                    <p>
                        <a-checkbox :checked="check2" @change="onChangecheck2">
                            {{this.$t('同意接受在panggame提供的活動抽獎 及遊戲服務同意通知相關sms訊息')}}
                        </a-checkbox>
                    </p>
                </div>
                <div>
                    <a-button style="margin-bottom: 6px;" @click="clickChangePhone" type="primary">
                        <span v-if="$store.state.user_info.phone">{{this.$t('修改')}}</span>
                        <span v-else>{{this.$t('添加')}}</span>
                    </a-button>
                    <a-button @click="unbund()" v-show="$store.state.user_info.phone" type="primary">{{this.$t('解绑')}}</a-button>
                </div>
            </li>
        </ul>
        <cName v-if="cname"></cName>
        <cPhone v-if="cphone"></cPhone>
        <cEmail v-if="cemail"></cEmail>
        <unbund v-if="isBund"></unbund>
    </div>
</template>

<script>
import cName from './DataChange/cName'
import cPhone from './DataChange/cPhone'
import cEmail from './DataChange/cEmail'
import unbund from './DataChange/unbund'
import request from "../../../api/request"
export default {
    components: {
      cName,
      cPhone,
      cEmail,
      unbund
    },
    data(){
        return{
            cname:false,
            cphone:false,
            cemail:false,
            isBund:false,
            check1:false,
            check2:false,
        }
    },
    mounted(){
        this.getMe()
        if(this.$store.state.user_info.ads == false){
            this.check1 = false
        }else{
            this.check1 = true
        }
        if(this.$store.state.user_info.ads_phone == false){
            this.check2 = false
        }else{
            this.check2 = true
        }
    },
    methods:{
        getMe(){
            let _this = this
            request.get('/users/me', {},true).then(function (res) {
                if (res.status == 200) {
                    if(res.data){
                        _this.$store.commit("setUserInfo", res.data);
                    }
                }
            })
        },
        onChangecheck1(e) {
            let _this = this
            _this.check1 = e.target.checked
            let data = {
                ads:_this.check1
            }
            request.put('/users/me', data, true).then(function (res) {
                if (res.status == 200) {
                 _this.$store.commit("setUserInfo", res.data);
                }
                else {
                    _this.$message.error(res.detail);
                }
            })
        },
        onChangecheck2(e) {
            let _this = this
            _this.check2 = e.target.checked
            let data = {
                ads_phone:_this.check2
            }
            request.put('/users/me', data, true).then(function (res) {
                if (res.status == 200) {
                 _this.$store.commit("setUserInfo", res.data);
                }
                else {
                    _this.$message.error(res.detail);
                }
            })
        },
        clickChangeData(){
            this.cname = !this.cname
        },
        clickChangeEmail(){
            this.cemail = !this.cemail
        },
        clickChangePhone(){
            this.cphone = !this.cphone
        },
        unbund(){
            if(!this.$store.state.user_info.email){
                this.$message.warning(this.$t('需要绑定邮箱才能解绑手机号'));
            }else{
               this.isBund = !this.isBund
            }
        }
    }
}
</script>

<style lang="scss">
.data{
    margin-top: 30px;
    .ant-checkbox-inner{
        width: 30px;
        height: 30px;
    }
    .ant-checkbox-inner::after{
        width: 10px;
        height: 20px;
    }
    .data-container{
        font-size: 20px;
        li{
            margin: 40px 0;
            display: flex;
            div{
                text-align: left;
                &:nth-child(1){
                    margin-right: 50px;
                    white-space:nowrap ;
                    width: 15%;
                }
                &:nth-child(2){
                    width: 65%;
                }
                &:nth-child(3){
                    width: 20%;
                    text-align: center;
                    padding-right: 50px;
                }
            }
        }
    }
    .btn-container{
        width: 80%;
        margin: 50px auto;
        display: flex;
        p{
            cursor: pointer;
            width: 45%;
            text-align: center;
            color: #fff;
            height: 50px;
            line-height: 50px;
            font-size: 22px;
            &:nth-child(1){
                background: #C5C9CE;
                margin-right: 30px;
            }
            &:nth-child(2){
                background: #2C82FF;
            }
        }
    }
}
</style>