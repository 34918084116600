<template>
  <div class="dialog" v-show="showMask">
    <div class="dialog-container">
      <div class="title">
        <slot name="title"></slot>
        <div class="close" @click="closeMask">
          <img src="../assets/img/icon_down.png" alt="" />
        </div>
      </div>
      <div class="content">
        <slot name="content"></slot>
      </div>
      <div class="btns">
        <div class="close-btn" @click="closeMask">{{ $t("取消") }}</div>
        <div class="default-btn" @click="closeBtn">
          {{ $t("確認") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    type: {
      type: String,
      default: "default",
    },
    content: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    confirmText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showMask: false,
    };
  },
  methods: {
    closeMask() {
      this.showMask = false;
    },
    closeBtn() {
      this.$emit("cancel");
      this.closeMask();
    },
  },
  mounted() {
    this.showMask = this.value;
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  .dialog-container {
    width: 500px;
    height: 600px;
    // padding: 30px;
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    position: relative;
    border-radius: 10px;
    @media only screen and (max-width: 900px) {
      width: 90%;
    }
    .dialog-title {
      width: 100%;
      height: 60px;
      font-size: 18px;
      color: #696969;
      font-weight: 600;
      padding: 16px 50px 0 20px;
      box-sizing: border-box;
    }
    .content {
      color: #797979;
      line-height: 26px;
      box-sizing: border-box;
    }
    .title {
      font-size: 36px;
      color: #fff;
      background: #252a2f;
      padding: 20px;
      display: flex;
      border-radius: 10px 10px 0 0;
      .close {
        flex-grow: 1;
        text-align: right;
        cursor: pointer;
      }
    }
    .btns {
      width: 100%;
      height: 60px;
      position: absolute;
      bottom: 0;
      left: 6px;
      text-align: right;
      padding: 0 16px;
      box-sizing: border-box;
      display: flex;
      font-size: 18px;
      & > div {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        color: #ffffff;
        background: #f1f1f1;
        border-radius: 5px;
        margin-right: 12px;
        cursor: pointer;
      }
      .default-btn {
        color: #fff;
        width: 48%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background-color: #2c82ff;
      }
      .close-btn {
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 18px;
        width: 48%;
        background-color: #c5c9ce;
      }
    }
  }
}
</style>
