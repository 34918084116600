<template>
    <div class="cancel-account">
        <div class="cancel-title">
            <p>{{$t('1.注销后，您将无法使用属于Panggame的所有游戏，会员服务以及现金使用等服务。')}}</p>
            <p>{{$t('2.注销后使用的帐户和昵称不能重复使用。')}}</p>
            <p>{{$t('3.注销过程结束后，所有信息将被删除。')}}</p>
        </div>
        <div class="canel-cont">
            <div class="cont-title">{{$t('*请选择手机或Email接收验证码确认后退出会员。')}}</div>
            <div class="canel-tab-bar">
                <span
                @click="tab(index)"
                v-for="(item,index) in items"
                :key="index"
                :class="{canelactive : index===curId}"
                >
                {{item.item}}
                </span>
            </div>
            <div class="canel-tab-cont">
                <div v-if="curId === 0" class="canel-phone">
                    <div class="item-name">{{this.$t('手機')}}：</div>
                    <div class="phone-container clearfix">
                        <div class="num-container">
                            <div class="top">
                            <select v-model="aaa" name="num">
                                <option value="82">KR+82</option>
                                <!-- <option value="886">TW+886</option>
                                <option value="852">HK+852</option>
                                <option value="853">MC+853</option>
                                <option value="66">66</option> -->
                            </select>
                            <div class="phone-box">
                                <div class="phone-box-ipt">
                                    <a-input 
                                    v-model="phone"
                                    placeholder="01XXXXXXXXX"
                                    type="text" 
                                    />
                                    <button v-show="countshow" @click="getCode(0)">
                                    <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                                    <span v-else>{{ this.$t("驗證碼") }}</span>
                                    </button>
                                    <button style="background: #d9d9d9; color: #333" v-show="!countshow">
                                    <span class="count">{{ count }} s</span>
                                    </button>
                                </div>
                                <div class="redcolor">{{phoneerr}}</div>
                            </div>
                            </div>
                        </div>
                        <div class="code-container">
                            <div>
                            <a-input 
                                v-model="code"
                                :placeholder="this.$t('請輸入驗證碼')"
                                type="text"
                            />
                            <div class="redcolor">{{phonecodeerr}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="canel-email">
                    <div class="item-name">{{this.$t('信箱：')}}</div>
                    <div class="email-container clearfix">
                        <div class="num-container">
                            <div class="top">
                            <div class="email-box">
                                <div class="email-box-ipt">
                                    <a-input
                                    v-model="email"
                                    placeholder="01XXXXXXXXX"
                                    type="text" 
                                    />
                                    <button v-show="countshow1" @click="getCode(1)">
                                    <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                                    <span v-else>{{ this.$t("驗證碼") }}</span>
                                    </button>
                                    <button style="background: #d9d9d9; color: #333" v-show="!countshow1">
                                    <span class="count">{{ count1 }} s</span>
                                    </button>
                                </div>
                                <div class="redcolor">{{emailerr}}</div>
                            </div>
                            </div>
                        </div>
                        <div class="code-container">
                            <div>
                            <a-input 
                                v-model="code1"
                                :placeholder="this.$t('請輸入驗證碼')"
                                type="text"
                            />
                            <div class="redcolor">{{emailcodeerr}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="canel-tips">
            {{this.$t('若手机号或Email都不再使用，请通过1:1询问人工处理。')}}
        </div>
        <div class="btn-container">
            <p @click="canel()"><span>{{this.$t('取消')}}</span></p>
            <p @click="openMask()">
                <loading v-show="isLoad"></loading>
                <span style="display: inline-block; margin-left: 10px">
                    {{this.$t("確認")}}
                </span>
            </p>
        </div>
        <dialogBar ref="ModalRejectclick" v-model="sendVal" type="default" title="我是标题" content="我是内容" v-on:cancel="clickSure()" @danger="clickDanger()" @confirm="clickConfirm()">
            <span slot="title"> 
                {{this.$t("注销账号")}}
            </span>
            <span slot="content"> 
                <div class="sure-cont">
                    <p><img src="../../../assets/img/icon_note.png" alt=""></p>
                    <p>{{this.$t('您是否确认注销账号？')}}</p>
                </div>
            </span>
        </dialogBar>
    </div>
</template>

<script>
import loading from '../../../components/Loading'
import  dialogBar  from '../../../components/Dialog'
import request from "../../../api/request"
import validate from "../../../utils/validate"
export default {
    components: {
        loading,
        dialogBar
    },
    data(){
        return{
            sendVal: false,
            fullWidth: 0,
            aaa: "82",
            isLoad:false,
            curId: 0,
            items: [
                {item: this.$t('手機')},
                {item: this.$t('信箱')},
            ],
            phone:'',
            code:'',
            countshow: true,
            count: '',
            timer: null,
            email:'',
            code1:'',
            countshow1: true,
            count1: '',
            timer1: null,
            phoneerr:'',
            phonecodeerr:'',
            emailerr:'',
            emailcodeerr:''
        }
    },
    methods:{
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
        tab(index) {
            this.curId = index;
        },
        openMask(){
            let _this = this
            if(_this.curId == 0){
                let res = validate.code(_this.code)
                if(res){
                    _this.phonecodeerr = _this.$t(res)
                    return
                }else{
                    _this.phonecodeerr = ''
                    _this.$refs.ModalRejectclick.showMask = true;
                }
            }
            if(_this.curId == 1){
                let res = validate.code(_this.code1)
                if(res){
                    _this.emailcodeerr = _this.$t(res)
                    return
                }else{
                    _this.emailcodeerr = ''
                    _this.$refs.ModalRejectclick.showMask = true;
                }
            }
        },
        clickSure(){
            let _this = this
            if(_this.curId == 0){
                let data = {
                    phone:_this.phone,
                    areacode:_this.aaa,
                    verify_source:1,
                    code:_this.code
                }
                request.post('/verify/deleteuser', data).then(function (res) {
                    if (res.status == 200) {
                        _this.$message.success(_this.$t("成功退出会员。"));
                        window.localStorage.removeItem('token')
                        window.localStorage.removeItem('user_info')
                        window.localStorage.removeItem('login_info')
                        setTimeout(function (){
                        _this.$router.push({
                            name:'register',
                            })
                        }, 2000)
                    }else{
                        _this.$message.error(res.detail);
                    }
                })
            }
            if(_this.curId == 1){
                let data = {
                    email:_this.email,
                    verify_source:2,
                    code:_this.code1
                }
                request.post('/verify/deleteuser', data).then(function (res) {
                    if (res.status == 200) {
                        _this.$message.success(_this.$t("成功退出会员。"));
                        window.localStorage.removeItem('token')
                        window.localStorage.removeItem('user_info')
                        window.localStorage.removeItem('login_info')
                        setTimeout(function (){
                        _this.$router.push({
                            name:'register',
                            })
                        }, 2000)
                    }else{
                        _this.$message.error(res.detail);
                    }
                })
            }

        },
        canelAccount(){

        },
        getCode(num){
            if(num == 0){
                let _this = this
                let res = validate.phone(_this.phone)
                if(res){
                    _this.phoneerr = _this.$t(res)
                    return
                }else{
                    _this.phoneerr = ''
                    const TIME_COUNT = 60;
                    if (!_this.timer) {
                        _this.count = TIME_COUNT;
                        _this.countshow = false;
                        _this.timer = setInterval(() => {
                        if (_this.count > 0 && _this.count <= TIME_COUNT) {
                            _this.count--;
                            } else {
                            _this.countshow = true;
                            clearInterval(_this.timer);
                            _this.timer = null;
                            }
                        }, 1000)
                    }
                    let data = {
                        phone:_this.phone,
                        areacode:_this.aaa,
                        verify_source:1,
                        verify_type:5
                    }
                    request.post('/verify/send', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("發送成功"));
                        }
                    })
                }
            }
            if(num == 1){
                let _this = this
                let res = validate.email(_this.email)
                if(res){
                    _this.emailerr = _this.$t(res)
                    return
                }else{
                    _this.emailerr = ''
                    const TIME_COUNT = 60;
                    if (!_this.timer1) {
                        _this.count1 = TIME_COUNT;
                        _this.countshow1 = false;
                        _this.timer1 = setInterval(() => {
                        if (_this.count1 > 0 && _this.count1 <= TIME_COUNT) {
                            _this.count1--;
                            } else {
                            _this.countshow1 = true;
                            clearInterval(_this.timer1);
                            _this.timer1 = null;
                            }
                        }, 1000)
                    }
                    let data = {
                        email:_this.email,
                        verify_source:2,
                        verify_type:5
                    }
                    request.post('/verify/send', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("發送成功"));
                        }
                    })
                }
            }
        }
    },
    mounted() {
        this.handleResize()
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss">
.cancel-account{
    margin-top: 50px;
    font-size: 20px;
    .sure-cont{
        text-align: center;
        font-size: 30px;
        color: #333333;
        font-weight: bold;
        img{
            display: inline-block;
            margin-bottom: 20px;
            margin-top: 100px;
        }
    }
    .canel-cont{
        margin: 50px 0 50px 0;
        .cont-title{
            color: #F41D1D;
        }
        .canel-tab-bar{
            font-size: 20px;
            margin:10px 20px 10px 0;
            span{
                color: #909499;
                cursor: pointer;
                display: inline-block;
                margin-right: 20px;
                text-align: center;
                font-weight: bold;
                padding: 2px 0;
                width: 100px;
            }
            .canelactive{
                color: #fff !important;
                background: #3E46FE;
            }
        }
        .canel-tab-cont{
            .canel-phone{
                display: flex;
                .item-name{
                    line-height: 50px; 
                    width: 16%;
                }
                .phone-container {
                    width: 73%;
                    .num-container {
                        float: left;
                        margin-right: 20px;
                        width: calc(80% - 20px);
                        @media only screen and (max-width: 900px) {
                            width: 100%;
                        }
                        .top {
                            display: flex;
                                select {
                                    width: 25%;
                                    margin-right: 20px;
                                    font-weight: 300;
                                    color: #999;
                                    border: 1px solid #d9d9d9;
                                    background: transparent;
                                    height: 50px;
                                    padding: 0 10px;
                                    option {
                                        border: none;
                                        background: transparent;
                                    }
                                }
                            .phone-box {
                                display: inline-block;
                                width: calc(70% - 20px);
                                .phone-box-ipt{
                                    border: 1px solid #d9d9d9;
                                }
                            input {
                                width: 55%;
                                height: 50px;
                                line-height: 50px;
                                color: #222;
                                border: none;
                                outline: none;
                                background: transparent;
                            }
                            button {
                                    width: 45%;
                                    height: 30px;
                                    line-height: 30px;
                                    font-size: 18px;
                                    background-color: #c6a86c;
                                    border-radius: 40px;
                                }
                            }
                        }
                    }
                    .code-container {
                        float: left;
                        width: 20%;
                        input {
                            height: 50px;
                            line-height: 50px;
                            color: #222;
                            border: 1px solid #d9d9d9;
                        }
                    }
                }
            }
            .canel-email{
                display: flex;
                .item-name{
                    line-height: 50px; 
                    width: 16%;
                }
                .email-container{
                    width: 73%;
                    .num-container {
                        float: left;
                        margin-right: 20px;
                        width: calc(80% - 20px);
                        @media only screen and (max-width: 900px) {
                            width: 100%;
                        }
                        .top {
                            display: flex;
                            select {
                                width: 30%;
                                margin-right: 20px;
                                font-weight: 300;
                                color: #999;
                                border: 1px solid #d9d9d9;
                                background: transparent;
                                height: 50px;
                                padding: 0 10px;
                            option {
                                    border: none;
                                    background: transparent;
                                }
                            }
                            .email-box {
                                display: inline-block;
                                width: calc(100% - 20px);
                                .email-box-ipt{
                                    border: 1px solid #d9d9d9;
                                }
                                input {
                                    width: 60%;
                                    height: 50px;
                                    line-height: 50px;
                                    color: #222;
                                    border: none;
                                    outline: none;
                                    background: transparent;
                                }
                                button {
                                        width: 40%;
                                        height: 30px;
                                        line-height: 30px;
                                        font-size: 18px;
                                        background-color: #c6a86c;
                                        border-radius: 40px;
                                    }
                                }
                            }
                        }
                    .code-container {
                        float: left;
                        width: 20%;
                        input {
                            height: 50px;
                            line-height: 50px;
                            color: #222;
                            border: 1px solid #d9d9d9;
                        }
                    }
                }
            }
        }
    }
    .canel-tips{
        text-align: center;
        margin: 30px 0;
        font-size: 18px;
        span{
            color: #2C82FF;
            cursor: pointer;
        }
    }
    .btn-container{
        width: 80%;
        margin: 50px auto;
        display: flex;
        p{
            cursor: pointer;
            width: 45%;
            text-align: center;
            color: #fff;
            height: 50px;
            line-height: 50px;
            font-size: 22px;
            &:nth-child(1){
                background: #C5C9CE;
                margin-right: 30px;
            }
            &:nth-child(2){
                background: #2C82FF;
            }
        }
    }
}
</style>