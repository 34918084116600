<template>
    <div class="cPhone">
        <div class="phoneipt">
            <ul>
                <li v-show="oPhone.phone != undefined">
                    <div class="item-name">{{this.$t('舊手機號碼：')}}</div>
                    <div class="phone-container clearfix">
                        <div class="num-container">
                            <div class="top">
                            <select v-model="aaa" name="num">
                                <option value="82">KR+82</option>
                                <!-- <option value="886">TW+886</option>
                                <option value="852">HK+852</option>
                                <option value="853">MC+853</option>
                                <option value="66">66</option> -->
                            </select>
                            <div class="phone-box">
                                <div class="phone-box-ipt">
                                    <a-input 
                                    v-model="oPhone.phone" 
                                    disabled
                                    placeholder="01XXXXXXXXX"
                                    type="text" 
                                    />
                                    <button v-show="countshow" @click="getCode(0)">
                                    <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                                    <span v-else>{{ this.$t("驗證碼") }}</span>
                                    </button>
                                    <button style="background: #d9d9d9; color: #333" v-show="!countshow">
                                    <span class="count">{{ count }} s</span>
                                    </button>
                                </div>
                                <div class="redcolor">{{phoneerr}}</div>
                            </div>
                            </div>
                        </div>
                        <div class="code-container">
                            <div>
                            <a-input 
                                v-model="oCode"
                                :placeholder="this.$t('請輸入驗證碼')"
                                type="text"
                            />
                            <div class="redcolor">{{codeerr}}</div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="item-name">{{this.$t('新手機號碼：')}}</div>
                    <div class="phone-container clearfix">
                        <div class="num-container">
                            <div class="top">
                            <select v-model="aaa" name="num">
                                <option value="82">KR+82</option>
                                <!-- <option value="886">TW+886</option>
                                <option value="852">HK+852</option>
                                <option value="853">MC+853</option>
                                <option value="66">66</option> -->
                            </select>
                            <div class="phone-box">
                                <div class="phone-box-ipt">
                                    <a-input 
                                    v-model="nPhone"
                                    placeholder="01XXXXXXXXX"
                                    type="text" 
                                    />
                                    <button v-show="countshow1" @click="getCode(1)">
                                    <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                                    <span v-else>{{ this.$t("驗證碼") }}</span>
                                    </button>
                                    <button style="background: #d9d9d9; color: #333" v-show="!countshow1">
                                    <span class="count">{{ count1 }} s</span>
                                    </button>
                                </div>
                                <div class="redcolor">{{phoneerragain}}</div>
                            </div>
                            </div>
                        </div>
                        <div class="code-container">
                            <div>
                            <a-input 
                                v-model="nCode"
                                :placeholder="this.$t('請輸入驗證碼')"
                                type="text"
                            />
                            <div class="redcolor">{{codeerragain}}</div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="btn-container">
            <p @click="canel()"><span>{{this.$t('取消')}}</span></p>
            <p @click="getSure()">
                <loading v-show="isLoad"></loading>
                <span style="display: inline-block; margin-left: 10px">{{
                this.$t("確認")
                }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import loading from '../../../../components/Loading'
import request from "../../../../api/request";
import validate from "../../../../utils/validate";
export default {
    components: {
        loading
    },
    data(){
        return{
            aaa: "82",
            fullWidth: 0,
            isLoad:false,
            oPhone:JSON.parse(window.localStorage.getItem("user_info")),
            nPhone:'',
            countshow: true,
            count: '',
            timer: null,
            oCode:'',
            nCode:'',
            countshow1: true,
            count1: '',
            timer1: null,
            codeerr:'',
            codeerragain:'',
            phoneerr:'',
            phoneerragain:''
        }
    },
    methods:{
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
        getCode(num){
            const TIME_COUNT = 60;
            let _this = this
            if(num == 0){
                let res = validate.phone(_this.oPhone.phone)
                if(res){
                    _this.phoneerr = _this.$t(res)
                    return
                }else{
                    _this.phoneerr = ''
                    if (!_this.timer) {
                        _this.count = TIME_COUNT;
                        _this.countshow = false;
                        _this.timer = setInterval(() => {
                            if (_this.count > 0 && _this.count <= TIME_COUNT) {
                                _this.count--;
                            } else {
                                _this.countshow = true;
                                clearInterval(_this.timer);
                                _this.timer = null;
                            }
                        }, 1000)
                    }
                    let data = {
                        phone:_this.oPhone.phone,
                        verify_source:1,
                        areacode:_this.aaa,
                        verify_type:2
                    }
                    request.post('/verify/send', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("發送成功"));
                        }
                    })
                }
            }
            if(num == 1){
                let res = validate.phone(_this.nPhone)
                if(res){
                    _this.phoneerragain = _this.$t(res)
                    return
                }else{
                    _this.phoneerragain = ''
                    if (!_this.timer1) {
                        _this.count1 = TIME_COUNT;
                        _this.countshow1 = false;
                        _this.timer1 = setInterval(() => {
                        if (_this.count1 > 0 && _this.count1 <= TIME_COUNT) {
                        _this.count1--;
                        } else {
                        _this.countshow1 = true;
                        clearInterval(_this.timer1);
                        _this.timer1 = null;
                        }
                        }, 1000)
                    }
                    if(_this.oPhone.phone == null){
                        let data = {
                        areacode:_this.aaa,
                        phone:_this.nPhone,
                        verify_source:1,
                        verify_type:2
                        }
                        request.post('/verify/send', data).then(function (res) {
                            if (res.status == 200) {
                                _this.$message.success(_this.$t("發送成功"));
                            }
                        })
                    }
                    if(_this.oPhone.phone != null){
                        let data = {
                        areacode:_this.aaa,
                        phone:_this.nPhone,
                        verify_source:1,
                        verify_type:2
                        }
                        request.post('/verify/send', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("發送成功"));
                        }
                        })
                    }
                }
                    
            }
        },
        getSure(){
            let _this = this
            if(_this.oPhone.phone == null){
                let res = validate.code(_this.nCode)
                if(res){
                    _this.codeerragain = _this.$t(res)
                    return
                }else{
                    _this.codeerr = ''
                    _this.codeerragain = ''
                    _this.phoneerr = ''
                    _this.phoneerragain = ''
                    let data = {
                        areacode:_this.aaa,
                        code:_this.nCode,
                        phone:_this.nPhone,
                        verify_source:1,
                        verify_type:2
                    }
                    _this.isLoad = true
                    request.post('/verify', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("绑定成功"));
                            _this.isLoad = false
                            setTimeout(function (){
                                _this.$router.go(0)
                            }, 2000)
                        }else{
                            _this.$message.error(res.detail);
                            _this.isLoad = false
                        }
                    })
                }
            }
            if(_this.oPhone.phone != null){
                let res = validate.code(_this.oCode)
                let resagain = validate.code(_this.nCode)
                if(res){
                    _this.codeerr = _this.$t(res)
                    return
                }else if(resagain){
                    _this.codeerragain = _this.$t(resagain)
                    return
                }else{
                    _this.codeerr = ''
                    _this.codeerragain = ''
                    _this.phoneerr = ''
                    _this.phoneerragain = ''
                    let data = {"new_phone":{"areacode":_this.aaa,"code":_this.nCode,"phone":_this.nPhone,"verify_source":1,"verify_type":2},"old_phone":{"areacode":_this.aaa,"code":_this.oCode,"phone":_this.oPhone.phone,"verify_source":1,"verify_type":2}}
                    _this.isLoad = true
                    request.post('/verify/phone', data).then(function (res) {
                        if (res.status == 200) {
                            _this.$message.success(_this.$t("修改成功"));
                            _this.isLoad = false
                            setTimeout(function (){
                                _this.$router.go(0)
                            }, 2000)
                        }else{
                            _this.$message.error(res.detail);
                            _this.isLoad = false
                        }
                    })
                }
            }
        },
        canel(){
            this.$router.go(0)
        }
    },
    mounted() {
        this.handleResize()
        console.log(JSON.parse(window.localStorage.getItem("user_info")).phone)
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss">
.cPhone{
    .phoneipt{
        ul{
            li{
                margin: 20px 0;
                display: flex;
                .item-name{
                   line-height: 50px; 
                   width: 20%;
                }
            }
        }
        input{
            font-size: 18px;
        }
        .phone-container {
            .num-container {
                float: left;
                margin-right: 20px;
                width: calc(80% - 20px);
                @media only screen and (max-width: 900px) {
                    width: 100%;
                }
                .top {
                    display: flex;
                        select {
                            width: 25%;
                            margin-right: 20px;
                            font-weight: 300;
                            color: #999;
                            border: 1px solid #d9d9d9;
                            background: transparent;
                            height: 50px;
                            padding: 0 10px;
                            option {
                                border: none;
                                background: transparent;
                            }
                        }
                    .phone-box {
                        display: inline-block;
                        width: calc(70% - 20px);
                        .phone-box-ipt{
                            border: 1px solid #d9d9d9;
                        }
                    input {
                        width: 55%;
                        height: 50px;
                        line-height: 50px;
                        color: #222;
                        border: none;
                        outline: none;
                        background: transparent;
                    }
                    button {
                            width: 45%;
                            height: 30px;
                            line-height: 30px;
                            font-size: 18px;
                            background-color: #c6a86c;
                            border-radius: 40px;
                        }
                    }
                }
            }
            .code-container {
                float: left;
                width: 20%;
                input {
                    height: 50px;
                    line-height: 50px;
                    color: #222;
                    border: 1px solid #d9d9d9;
                }
            }
        }
    }
}
</style>