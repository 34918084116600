import { render, staticRenderFns } from "./pwdPhone.vue?vue&type=template&id=4d393b51"
import script from "./pwdPhone.vue?vue&type=script&lang=js"
export * from "./pwdPhone.vue?vue&type=script&lang=js"
import style0 from "./pwdPhone.vue?vue&type=style&index=0&id=4d393b51&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports