<template>
    <div class="pwdPhone">
        <div class="ipt">
            <div class="item-name">{{this.$t('舊手機號碼：')}}</div>
            <div class="phone-container clearfix">
                <div class="num-container">
                    <div class="top">
                    <select v-model="aaa" name="num">
                        <option value="82">KR+82</option>
                        <!-- <option value="886">TW+886</option>
                        <option value="852">HK+852</option>
                        <option value="853">MC+853</option>
                        <option value="66">66</option> -->
                    </select>
                    <div class="phone-box">
                        <div class="phone-box-ipt">
                            <a-input 
                            v-model="phone"
                            placeholder="01XXXXXXXXX"
                            type="text" 
                            />
                            <button v-show="countshow" @click="getCode()">
                            <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
                            <span v-else>{{ this.$t("驗證碼") }}</span>
                            </button>
                            <button style="background: #d9d9d9; color: #333" v-show="!countshow">
                            <span class="count">{{ count }} s</span>
                            </button>
                        </div>
                        <div class="redcolor">{{phoneErr}}</div>
                    </div>
                    </div>
                </div>
                <div class="code-container">
                    <div>
                    <a-input 
                        v-model="code"
                        :placeholder="this.$t('請輸入驗證碼')"
                        type="text"
                    />
                    <div class="redcolor">{{codeErr}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pwd">
            <div class="item-name">{{this.$t('輸入新密碼')}}:</div>
            <div class="item-pwd">
                <a-input-password v-model="pwd" :placeholder="this.$t('輸入新密碼')" />
                <div class="redcolor">{{pwdErr}}</div>
            </div>
        </div>
        <div class="pwdagain">
            <div class="item-name">{{this.$t('請確認密碼')}}:</div>
            <div class="item-pwd">
                <a-input-password v-model="pwdagain" :placeholder="this.$t('輸入新密碼')" />
                <div class="redcolor">{{pwdErrAgain}}</div>
            </div>
        </div>
        <div class="btn-container">
            <p @click="canel()"><span>{{this.$t('取消')}}</span></p>
            <p @click="getSure()">
                <loading v-show="isLoad"></loading>
                <span style="display: inline-block; margin-left: 10px">{{
                this.$t("確認")
                }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import loading from '../../../../components/Loading'
import request from "../../../../api/request";
import validate from "../../../../utils/validate";
import {encrypt} from '../../../../utils/jsencrypt'
export default {
    components: {
        loading
    },
    data(){
        return{
            aaa: "82",
            fullWidth: 0,
            isLoad:false,
            username:'',
            phone:'',
            code:'',
            countshow: true,
            count: '',
            timer: null,
            pwd:'',
            pwdagain:'',
            phoneErr:'',
            codeErr:'',
            pwdErr:this.$t('*密码至少要包含8位英文和数字'),
            pwdErrAgain:this.$t('*密码至少要包含8位英文和数字'),
        }
    },
    methods:{
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
        getCode(){
            let _this = this
            const TIME_COUNT = 60;
            let res = validate.phone(_this.phone)
            if(res){
                _this.phoneErr = _this.$t(res)
                return
            }else{
                _this.phoneErr = ''
                if (!_this.timer) {
                    _this.count = TIME_COUNT;
                    _this.countshow = false;
                    _this.timer = setInterval(() => {
                    if (_this.count > 0 && _this.count <= TIME_COUNT) {
                        _this.count--;
                        } else {
                        _this.countshow = true;
                        clearInterval(_this.timer);
                        _this.timer = null;
                        }
                    }, 1000)
                }
                let data = {
                    phone:_this.phone,
                    areacode:_this.aaa,
                    verify_source:1,
                    verify_type:4
                }
                request.post('/verify/send', data).then(function (res) {
                    if (res.status == 200) {
                        _this.$message.success(_this.$t("發送成功"));
                    }
                })      
            }
            
        },
        getSure(){
            let _this = this
            let resCode = validate.code(_this.code)
            let resPwd = validate.password(_this.pwd)
            let resPwdagain = validate.password(_this.pwdagain)
            if(resCode){
                _this.codeErr = _this.$t(resCode)
                return
            }else{
                _this.codeErr = ''
            }
            if(resPwd){
                _this.pwdErr = _this.$t(resPwd)
                return
            }else{
                _this.pwdErr = ''
            }
            if(resPwdagain){
                _this.pwdErrAgain = _this.$t(resPwdagain)
                return
            }else{
                _this.pwdErrAgain = ''
            }
            let data = {
                phone:_this.phone,
                areacode:_this.aaa,
                verify_source:1,
                verify_type:4,
                code:_this.code
            }
            _this.isLoad = true
            request.post('/verify/check', data).then(function (res) {
                if (res.status == 200) {
                    _this.check()
                    _this.isLoad = false
                }else{
                   _this.$message.error(res.detail);
                    _this.isLoad = false
                }
            })
        },
        check(){
            let _this = this
            let data = {
                phone:_this.phone,
                password :encrypt(_this.pwdagain),
                verify_source:1,
                verify_type:4,
                code:_this.code,
                areacode:_this.aaa
            }
            request.post('/verify/password', data).then(function (res) {
                if (res.status == 200) {
                    _this.$message.success(_this.$t("修改成功"));
                    setTimeout(function (){
                    _this.$router.go(0)
                    }, 2000)
                }else{
                    _this.$message.error(res.detail);
                }
            })
        },
        canel(){
            this.$router.go(0)
        }
    },
    mounted() {
        this.handleResize()
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss">
.pwdPhone{
    .ipt{
        display: flex;
        .item-name{
            line-height: 50px; 
            width: 25%;
        }
        
        .phone-container {
            width: 75%;
            .num-container {
                float: left;
                margin-right: 20px;
                width: calc(80% - 20px);
                @media only screen and (max-width: 900px) {
                    width: 100%;
                }
                .top {
                    display: flex;
                        select {
                            width: 25%;
                            margin-right: 20px;
                            font-weight: 300;
                            color: #999;
                            border: 1px solid #d9d9d9;
                            background: transparent;
                            height: 50px;
                            padding: 0 10px;
                            option {
                                border: none;
                                background: transparent;
                            }
                        }
                    .phone-box {
                        display: inline-block;
                        width: calc(70% - 20px);
                        .phone-box-ipt{
                            border: 1px solid #d9d9d9;
                        }
                    input {
                        width: 55%;
                        height: 50px;
                        line-height: 50px;
                        color: #222;
                        border: none;
                        outline: none;
                        background: transparent;
                    }
                    button {
                            width: 45%;
                            height: 30px;
                            line-height: 30px;
                            font-size: 18px;
                            background-color: #c6a86c;
                            border-radius: 40px;
                        }
                    }
                }
            }
            .code-container {
                float: left;
                width: 20%;
                input {
                    height: 50px;
                    line-height: 50px;
                    color: #222;
                    border: 1px solid #d9d9d9;
                }
            }
        }
    }
    .pwd{
        display: flex;
        margin: 30px 0;
        .item-name{
            line-height: 50px; 
            width: 25%;
        }
        .item-pwd{
            width: 75%;
        }
        input{
            height: 50px;
            line-height: 50px;
            font-size: 20px;
        }
    }
    .pwdagain{
        display: flex;
        .item-name{
            line-height: 50px; 
            width: 25%;
        }
        .item-pwd{
            width: 75%;
        }
        input{
            height: 50px;
            line-height: 50px;
            font-size: 20px;
        }
    }
}
</style>